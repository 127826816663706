import logo from "./logo.svg";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./App.css";
import React from "react";
import Lottie from "react-lottie";
import Illustration from "./Animation - 1730226034073 (2).json";

function App() {
  const ref = React.useRef(null);
  React.useEffect(() => {
    ref.current.style.marginTop = "1999px";
    setTimeout(() => {
      ref.current.style.transform = "rotateZ(720deg)";
      ref.current.style.marginTop = "0px";
    }, 500);

    // document.querySelector(".coming").style.opacity = ".1";
    // document.querySelector(".coming").style.transform = "translateY(90)";
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Illustration,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(./background.jpg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className="main"
    >
      <Container>
        <Row>
          <Col xs={0} md={1}></Col>
          <Col xs={12} md={10}>
            <Container className="coming" ref={ref}>
              <Row>
                <Col xs={12} md={6} className="left">
                  <center>
                    <font
                      style={{
                        marginBottom: "40px",
                        fontSize: "40px",
                        fontWeight: "bold",
                        color: "rgb(219 157 0)",
                      }}
                    >
                      The dates and nuts orchard
                    </font>
                  </center>

                  <font
                    style={{
                      fontSize: "60px",
                      fontWeight: "bold",
                      color: "rgb(219 157 0)",
                    }}
                  >
                    Coming Soon
                  </font>
                </Col>
                <Col xs={12} md={6} className="right">
                  <Lottie options={defaultOptions} style={{ height: "50%" }} />
                  <div style={{ height: "50%", width: "100%" }}>
                    <br />
                    <center>
                      <img src="./logo.png" style={{ width: "50%" }} />
                    </center>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Col xs={0} md={1}></Col>
      </Container>
    </div>
  );
}

export default App;
